import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import './layout.css';

const Layout = ({ children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
				logo: file(relativePath: { eq: "logo.png" }) {
					childImageSharp {
						fluid(maxWidth: 400, quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`}
		render={data => (
			<>
				<Helmet
					link={[
						{
							rel: 'stylesheet',
							href:
								'https://fonts.googleapis.com/css?family=Lato:300,400,700|Poppins:700i'
						},
						{
							rel: 'stylesheet',
							href:
								'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css'
						},
						{
							rel: 'stylesheet',
							href:
								'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
						},
						{
							rel: 'stylesheet',
							href:
								'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
						}
					]}
				>
					<html lang="en" />
				</Helmet>
				<Header logo={data.logo} siteTitle={data.site.siteMetadata.title} />
				<div>{children}</div>
				<Footer />
			</>
		)}
	/>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
