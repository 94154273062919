import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import { TransitionMixin, media } from '../components/helpers';
import Meta from '../components/meta';
const StyledAbout = styled.div``;

const HalfBanner = styled.div`
	position: relative;

	.gatsby-image-wrapper {
		height: 275px;
	}

	.overlay {
		background: rgba(0, 0, 0, 0.4);
		position: absolute;
		top: 0px;
		bottom: 0px;
		height: 100%;
		width: 100%;
	}

	.content-wrap {
		position: absolute;
		top: 50%;
		width: 100%;
		text-align: center;

		h1 {
			color: #fff;
			font-weight: 300;
			margin: 0px;
			font-size: 24px;

			${media.medium`font-size: 2.25rem;`};
		}
	}
`;

const InfoWrap = styled.div`
	.inner-wrap {
		max-width: 90vw;
		margin: 40px auto;
		display: flex;
		flex-wrap: wrap;

		> * {
			flex: 1 1 100%;

			${media.medium`flex: 1 1 50%;`};
		}

		.image-section {
			margin-bottom: 20px;
			.small-title {
				background: #222;
				text-align: center;
				padding: 15px 0px;
				max-width: 300px;
				margin: 0 auto;
				h3 {
					color: #fff;
					margin: 0px;
					font-weight: 300;
				}
			}
			.gatsby-image-wrapper {
				margin: 0 auto;
				max-height: 370px;
				max-width: 300px;
				overflow: hidden;

				img {
					object-position: top !important;
				}
			}

			.small-blurb {
				max-width: 300px;
				padding: 15px 0;
				margin: 0 auto;
				border: 1px solid #efefef;
				.credit {
					padding: 0px 10px;
					font-size: 10px;
					font-weight: normal;
					span {
						font-weight: bold;
					}
				}
				p {
					font-size: 13px;
					font-weight: 300;
					line-height: 23px;
					color: #222;
					padding: 0px 10px;
				}
			}
		}

		.content-section {
			p {
				font-weight: 400;
				line-height: 25px;
				font-size: 15px;
			}

			ul {
				padding-left: 10px;
				margin: 0px;
				li {
					flex: 1 1 30%;
					font-size: 13px;
					margin: 20px 10px;
					align-self: center;
					justify-self: left;
				}
			}
		}
	}
`;

const AboutPage = ({ data }) => {
	const banner = data.banner.childImageSharp;
	const prof = data.prof.childImageSharp;
	return (
		<Layout>
			<Meta
				title="About | Davidoff Consulting Group SF"
				description="Liz Davidoff has over 25 years experience in the entertainment and technology arenas. She launched Davidoff Consulting Group in 2012 to deliver marketing expertise to high growth and innovative companies."
			/>
			<StyledAbout>
				<HalfBanner>
					<Img fluid={banner.fluid} />
					<div className="overlay" />

					<div className="content-wrap">
						<h1>About Davidoff Consulting Group</h1>
					</div>
				</HalfBanner>

				<InfoWrap>
					<div className="inner-wrap">
						<div className="image-section">
							<div className="small-title">
								<h3>Principal</h3>
							</div>
							<Img fluid={prof.fluid} />

							<div className="small-blurb">
								{/* <p>
									Liz Davidoff has over 25 years experience in the entertainment
									and technology arenas. She launched Davidoff Consulting Group
									in 2012 to deliver marketing expertise to high growth and
									innovative companies. She partners with both start-ups and
									mature companies to deliver proven Integrated Marketing
									experience that drives growth and profitability for their
									portfolio of products and services.
								</p> */}
								<div className="credit">
									<span>Photo by Joel Lipton,</span>{' '}
									<a href="http://joellipton.com" tagret="_blank">JoelLipton.com</a>
								</div>
							</div>
						</div>
						<div className="content-section">
							<p>
								Davidoff Consulting Group provides Thought Leadership and Marketing Expertise for High Growth and Innovative Technology and Media companies.
							</p>
							<p>
								Liz Seidner Davidoff, Principal, founded the company in 2012.
								Her mission is helping companies create a strong marketing
								foundation to drive growth, profitability and brand awareness.
								Her passion is partnering with innovative companies to act as a
								nimble and effective extension of their Marketing organization
								and help them fast track key marketing initiatives.
							</p>

							<p>
								Contributing proven experience and access to a high caliber team
								of experts, Davidoff Consulting Group offers specialized
								Marketing support including brand design, social media strategy,
								creative development, global event management support and
								specialized market research.
							</p>

							<p>
								Liz also partners with early stage companies as interim Head of
								Marketing to establish and lead their Marketing organizations
								while creating a solid foundation for effective brand visibility
								and promotion of their new products or services.
							</p>

							<p>Areas of Expertise:</p>
							<ul>
								<li>
									Integrated Marketing Strategy &amp; Messaging Development
								</li>
								<li>Establishing Marketing/PR Teams</li>
								<li>Creative Agency Management</li>
								<li>Strategic &amp; Tactical Planning</li>
								<li>Specialized Industry Research</li>
								<li>Global Event Management</li>
							</ul>
						</div>
					</div>
				</InfoWrap>
			</StyledAbout>
		</Layout>
	);
};

export default AboutPage;

export const AboutPageQuery = graphql`
	query {
		banner: file(relativePath: { eq: "about.jpeg" }) {
			childImageSharp {
				fluid(maxWidth: 2100, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}

		prof: file(relativePath: { eq: "new-prof.jpg" }) {
			childImageSharp {
				fluid(maxWidth: 600, quality: 100) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
