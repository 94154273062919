import React from 'react';
import styled from 'styled-components';

const FooterWrap = styled.div`
	background: #2a2a2a;
	padding: 15px;
	.inner-wrap {
		text-align: center;

		p {
			margin: 0px;
			color: #fff;
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 1px;
		}
	}
`;

const Footer = () => {
	const year = new Date().getFullYear();
	return (
		<FooterWrap>
			<div className="inner-wrap">
				<p>&copy; {year} Davidoff Consulting Group. All rights reserved.</p>
			</div>
		</FooterWrap>
	);
};

export default Footer;
