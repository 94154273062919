import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import Img from 'gatsby-image';
import { media, TransitionMixin } from '../components/helpers';

const HamburgerWrap = styled.div`
	${media.medium`display: none;`};
	.hamburger {
		padding: 15px 15px;
		display: inline-block;
		cursor: pointer;
		transition-property: opacity, filter;
		transition-duration: 0.15s;
		transition-timing-function: linear;
		font: inherit;
		color: inherit;
		text-transform: none;
		background-color: transparent;
		border: 0;
		margin: 0;
		overflow: visible;
	}
	.hamburger:hover {
		opacity: 0.7;
	}
	.hamburger.is-active:hover {
		opacity: 0.7;
	}
	.hamburger.is-active .hamburger-inner,
	.hamburger.is-active .hamburger-inner::before,
	.hamburger.is-active .hamburger-inner::after {
		background-color: #fff;
	}

	.hamburger-box {
		width: 40px;
		height: 24px;
		display: inline-block;
		position: relative;
	}

	.hamburger-inner {
		display: block;
		top: 50%;
		margin-top: -2px;
	}
	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		width: 40px;
		height: 2px;
		background-color: #fff;
		border-radius: 4px;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}
	.hamburger-inner::before,
	.hamburger-inner::after {
		content: '';
		display: block;
	}
	.hamburger-inner::before {
		top: -10px;
	}
	.hamburger-inner::after {
		bottom: -10px;
	}

	.hamburger--slider .hamburger-inner {
		top: 2px;
	}
	.hamburger--slider .hamburger-inner::before {
		top: 10px;
		transition-property: transform, opacity;
		transition-timing-function: ease;
		transition-duration: 0.15s;
	}
	.hamburger--slider .hamburger-inner::after {
		top: 20px;
	}

	.hamburger--slider.is-active .hamburger-inner {
		transform: translate3d(0, 10px, 0) rotate(45deg);
	}
	.hamburger--slider.is-active .hamburger-inner::before {
		transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
		opacity: 0;
	}
	.hamburger--slider.is-active .hamburger-inner::after {
		transform: translate3d(0, -20px, 0) rotate(-90deg);
	}
`;

const StyledHeader = styled.div`
	position: absolute;
	width: 100%;
	z-index: 2000;
	top: 0;
	> .inner-wrap {
		display: flex;
		max-width: 90vw;
		margin: 0 auto;
		position: relative;
		z-index: 60;
		> * {
			flex: 1;
		}

		.logo-section {
			.inner-wrap {
				a {
				}
			}
			.gatsby-image-wrapper {
				max-width: 90px;
				min-width: 90px;
				display: inline-block;
				${media.medium`max-width: 150px; min-width: 150px;`};
			}
		}

		.link-section {
			align-self: center;
			text-align: right;

			> ul {
				margin: 0px;
				padding: 0px;
				display: none;
				${media.medium`display: block;`};
				li {
					display: inline-block;
					margin: 0px 10px;
					list-style: none;
					a {
						color: #fff;
						text-decoration: none;
						font-weight: 300;
						letter-spacing: 1px;
						text-transform: uppercase;
						font-size: 12px;
					}
				}
			}
		}
	}

	.mobile-menu {
		background: #2a2a2a;
		height: 100vh;
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 50;
		transform: translateY(-100%);
		${TransitionMixin('.25s')};

		&.is-active {
			transform: translateY(0%);
		}

		${media.medium`display: none;`};

		> .inner-wrap {
			padding-top: 140px;
			max-width: 90vw;
			margin: 0 auto;

			ul {
				li {
					list-style: none;

					a {
						color: #fff;
						text-transform: uppercase;
						text-decoration: none;
						letter-spacing: 1.5px;
						font-size: 13px;

						i {
							font-size: 30px;
						}
					}
				}
			}
		}
	}
`;

class Header extends React.Component {
	state = {
		mobileMenuOpen: false
	};

	toggleHamburger = () => {

		const hamburger = document.querySelector('#hamburger');
		const menu = document.querySelector('#mm');
		if (hamburger.classList.contains('is-active')) {
			hamburger.classList.remove('is-active');
			menu.classList.remove('is-active');
		} else {
			hamburger.classList.add('is-active');
			menu.classList.add('is-active');
		}
	};
	render() {
		const { logo } = this.props;
		return (
			<StyledHeader>
				<div className="inner-wrap">
					<div className="logo-section">
						<div className="inner-wrap">
							<Link to="/">
								<Img fluid={logo.childImageSharp.fluid} />
							</Link>
						</div>
					</div>
					<div className="link-section">
						<div className="mobile">
							<HamburgerWrap>
								<button
									className="hamburger hamburger--slider"
									id="hamburger"
									onClick={this.toggleHamburger}
								>
									<div className="hamburger-box">
										<div className="hamburger-inner" />
									</div>
								</button>
							</HamburgerWrap>
						</div>
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/about">About</Link>
							</li>
							<li>
								<a href="mailto:liz@davidoffconsultingsf.com">Contact</a>
							</li>
							<li>
								<a
									target="_blank"
									href="https://www.linkedin.com/in/lizseidnerdavidoff/"
									rel="noopener noreferrer"
								>
									<i className="fa fa-linkedin" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="mobile-menu" id="mm">
					<div className="inner-wrap">
						<ul>
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/about">About</Link>
							</li>
							<li>
								<a href="mailto:liz@davidoffconsultingsf.com">Contact</a>
							</li>
							<li>
								<a
									target="_blank"
									href="https://www.linkedin.com/in/lizseidnerdavidoff/"
									rel="noopener noreferrer"
								>
									<i className="fa fa-linkedin" />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</StyledHeader>
		);
	}
}

export default Header;
