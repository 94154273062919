import React, { Component } from 'react'
import Helmet from 'react-helmet'

export default class Meta extends Component {
  render() {
    const { title, description, source_url } = this.props
    return (
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {source_url && <meta name="og:image" content={source_url} />}
      </Helmet>
    )
  }
}
